<template>
    <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
        <v-col class="text-start mt-5 mb-3">
            <v-alert text dense color="#8061c2" border="left" class="pl-5">
                <h1 class="title mt-1">Retiros pendientes</h1>
                <p class="desc mt-0 mb-1">
                    En esta sección podrás visualizar los retiros pendientes de los usuarios y realizar las acciones
                    correspondientes.
                </p>
            </v-alert>
        </v-col>

        <!-- Search Bar -->
        <v-row class="mx-3">
            <v-col cols="12" md="4" class="px-0">
                <v-text-field v-model="search" color="#8061c2" label="Buscar retiros" append-icon="mdi-magnify"
                    single-line hide-details outlined class="mb-2 mx-0 px-0" @input="fetchWithdrawals"></v-text-field>
            </v-col>

            
            <v-col cols="12" md="4" class="px-md-2 px-0">
                <v-select v-model="filter" :items="filterOptions" label="Filtrar por" outlined hide-details></v-select>
            </v-col>

            <v-col cols="12" md="4" class="px-0 ">
                <v-select v-model="order" :items="orderOptions" label="Ordenar" outlined hide-details></v-select>
            </v-col>
        </v-row>

        <v-data-table :headers="headers" :items="withdrawals" :loading="loading" class="elevation-1 mx-3 px-2 py-4"
            :items-per-page="perPage" :server-items-length="totalWithdrawals" :page.sync="page" :footer-props="{
                itemsPerPageOptions: [50, 100, 200, 300, -1],
                showFirstLastPage: true
            }" @update:page="fetchWithdrawals" @update:items-per-page="changePerPage">
            <template v-slot:item.user_logo_path="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="40" class="clickable-avatar" @click="showLogo(item.user_logo_path)"
                            @mouseover="hoveredLogo = item.user_logo_path" @mouseleave="hoveredLogo = null"
                            v-bind="attrs" v-on="on">
                            <v-img :src="item.user_logo_path" v-if="item.user_logo_path"></v-img>
                            <v-avatar v-else color="black">
                                <span class="white--text">{{ item.user_first_name.charAt(0) }}{{
                                    item.user_last_name.charAt(0) }}</span>
                            </v-avatar>
                        </v-avatar>
                    </template>
                    <span>Ver Imagen</span>
                </v-tooltip>
            </template>

            <template v-slot:item.status_withdrawal_name="{ item }">
                <v-chip :color="getStatusColor(item.status_withdrawal_name)" dark small>
                    {{ item.status_withdrawal_name }}
                </v-chip>
            </template>



            <template v-slot:item.amount="{ item }">
                $ {{ item.amount }} USD
            </template>



            <template v-slot:item.payment_method="{ item }">
                {{ item.payment_method === 'bank' ? 'Cuenta de banco' : '' }}
                {{ item.payment_method === 'crypto' ? 'Criptomoneda' : '' }}
                {{ item.payment_method === 'cosmo' ? 'Cosmo' : '' }}
                {{ item.payment_method === 'paypal' ? 'PayPal' : '' }}
            </template>

            <template v-slot:item.actions="{item}">
                <v-btn
                    color="success"
                    @click="payWithdrawal(item.id)"
                    :loading="item.loading"
                    :disabled="item.loading"
                    class="elevated-btn mr-4"
                    small
                >
                    <v-icon left>mdi-cash-fast</v-icon>
                    Pagar
                </v-btn>

                <v-btn
                    color="error"
                    @click="rejectWithdrawal(item.id)"
                    :loading="item.loading"
                    :disabled="item.loading"
                    class="elevated-btn"
                    small
                >
                    <v-icon left>mdi-cash-remove</v-icon>
                    Rechazar
                </v-btn>
            </template>

            <template v-slot:item.details_metodo="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on"
                            @click="showDetails(item)">
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </template>
                    <span>Ver Detalles</span>
                </v-tooltip>
            </template>


        </v-data-table>


        <!-- Modal Informacion del metodo de pago -->
        <ModalInfoPay :dialogDetails.sync="dialogDetails" :withdrawalMethod="withdrawalMethod" :paymentDetails="paymentDetails"
            @close="dialogDetails = false"></ModalInfoPay>

        <!-- Modal para ampliar el logo -->
        <v-dialog v-model="dialog" max-width="14rem" class="rounded-lg">
            <v-img :src="selectedLogo" width="14rem"></v-img>
        </v-dialog>
    </v-col>
</template>

<script>
import axios from '@/axios.js';
import ModalInfoPay from '@/components/partials/admin/ModalInfoPay.vue';

export default {
    components: {
        ModalInfoPay
    },

    data() {
        return {
            roles: [],
            search: '',
            loading: true,
            saveLoading: false,
            dialog: false,
            selectedLogo: null,
            show1: false,
            page: 1,
            perPage: 50,
            withdrawals: [],
            totalWithdrawals: 0,
            dialogDetails: false,
            withdrawalMethod: null,
            paymentDetails: {},
            filter: 'created_at',
            order: 'asc',
            headers: [
                { text: 'Avatar', value: 'user_logo_path', align: 'start', sortable: false },
                { text: 'Email', value: 'user_email' },
                { text: 'Monto', value: 'amount' },
                { text: 'Metodo de pago', value: 'payment_method' },
                { text: 'Estado', value: 'status_withdrawal_name' },
                { text: 'Detalles de metodo de pago', value: 'details_metodo', sortable: false, align: 'center', },
                { text: 'Fecha de retiro', value: 'created_at' },
                {text: 'Actions', value: 'actions', sortable: false, align: 'center'},
            ],
            filterOptions: [
                {
                    text: 'Fecha Creación',
                    value: 'created_at'
                },
                {
                    text: 'Monto',
                    value: 'amount'
                },
    
            ],
            orderOptions: [
                {
                    text: 'Mayor',
                    value: 'desc'
                },
                {
                    text: 'Menor',
                    value: 'asc'
                }
            ],
            rules: {
                required: value => !!value || this.$t('navbar.required'),
                min: v => v.length >= 8 || this.$t('navbar.min'),
                minPhone: v => v.length >= 6 || this.$t('navbar.min-phone'),
                maxPhone: v => v.length <= 12 || this.$t('navbar.max-phone'),
                numericPhone: v => /^[0-9]+$/.test(v) || this.$t('navbar.numeric-phone'),
                alpha: v => /^[a-zA-ZÑñ ]*$/.test(v) || this.$t('navbar.alpha'),
                minName: v => v.length >= 3 || this.$t('navbar.min-name'),
                maxName: v => v.length <= 30 || this.$t('navbar.max-name'),
                email: value => {
                    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    return pattern.test(value) || this.$t('navbar.invalid-email')
                }
            }
        };
    },

    created() {
        this.fetchWithdrawals();
    },

    methods: {
        showLogo(logoPath) {
            this.selectedLogo = logoPath ? logoPath : require('../../assets/illustrations/no-photo.jpg');
            this.dialog = true;
        },
        async fetchWithdrawals() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/get-withdrawals', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        list: 'pending_process',
                        order: this.order,
                        filter: this.filter
                    }
                });
                this.withdrawals = response.data.withdrawals.data.map(withdrawal => ({
                    ...withdrawal,
                    loading: false
                }));
                this.totalWithdrawals = response.data.total;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1; // Reiniciar a la primera página al cambiar los elementos por página
            this.fetchWithdrawals();
        },

        getStatusColor(statusName) {
            if (statusName.toLowerCase() === 'solicitado') {
                return '#f29339';
            } else if (statusName.toLowerCase() === 'en proceso') {
                return '#8e84c0';
            } else if (statusName.toLowerCase() === 'pagado') {
                return '#2e8b57';
            }
        },

        showDetails(item) {
            this.withdrawalMethod = item.payment_method;
            this.paymentDetails = item.payment_details || {};
            this.dialogDetails = true;
        },

        async payWithdrawal(id) {
            const index = this.withdrawals.findIndex(withdrawal => withdrawal.id === id);
            this.$swal({
                title: 'Pagar retiro',
                text: '¿Estás seguro de que deseas pagar este retiro?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#8e84c0',
                cancelButtonColor: '#d33',
            }).then(async (result) => { 
                if (result.isConfirmed) {
                    this.withdrawals[index].loading = true;
                    try {
                        const response = await axios.post('/admin/pay-withdrawal', {
                            withdrawal_id: id
                        });
                        this.fetchWithdrawals();
                        if (response.data.success) {
                            this.$vs.notify({
                                title: 'Pago exitoso',
                                text: 'El retiro ha sido pagado exitosamente',
                                color: 'success',
                                icon: 'check_box',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.$toast.error('Ha ocurrido un error al pagar el retiro');
                    } finally {
                        this.withdrawals[index].loading = false;
                    }
                }
            });
        },

        async rejectWithdrawal(id) {
            const index = this.withdrawals.findIndex(withdrawal => withdrawal.id === id);
            this.$swal({
                title: 'Rechazar retiro',
                text: '¿Estás seguro de que deseas rechazar este retiro?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#8e84c0',
                cancelButtonColor: '#d33',
                input: 'text',
                inputPlaceholder: 'Ingrese el motivo del rechazo',
                inputValidator: (value) => {
                    if (!value) {
                        return 'Debe ingresar un motivo para el rechazo';
                    }
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const reason = result.value; // Captura el motivo del rechazo
                    console.log(reason);
                    this.withdrawals[index].loading = true;
                    try {
                        const response = await axios.post('/admin/reject-withdrawal', {
                            withdrawal_id: id,
                            reason: reason // Incluye el motivo en la solicitud
                        });
                        this.fetchWithdrawals();
                        if (response.data.success) {
                            this.$vs.notify({
                                title: 'Retiro rechazado',
                                text: 'El retiro ha sido rechazado exitosamente',
                                color: 'success',
                                icon: 'check_box',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.$toast.error('Ha ocurrido un error al rechazar el retiro');
                    } finally {
                        this.withdrawals[index].loading = false;
                    }
                }
            });
        }
    },
    
    watch: {
        order(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchWithdrawals();
            }
        },
        filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchWithdrawals();
            }
        }
    },
};
</script>

<style scoped>

.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}

.elevated-btn {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-weight: 600;
    text-transform: uppercase;
}

.title-modal {
    background-color: #8e84c0;
    color: white;
}
</style>
